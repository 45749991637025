export enum THANK_YOU_ROUTE {
  BASE = 'thank-you',
  FULL_ROUTE = '/thank-you',
}

export type CalendlyUrls = {
  [carrier: string]: {
    [product: string]: {
      [whiteLabel: string]: string;
    };
  };
};
